import { graphql } from 'gatsby';
import { arrayOf, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';
import { pictureFluidType } from '../../../types/picture';
import { Content } from '../../common/content';
import { FluidPicture } from '../../common/picture';
import './generic.scss';

const bem = new BEMHelper({
  name: 'content-generic-heading',
});

export const GenericHeading = ({
  headline,
  description,
  image: [image],
}) => {
  const output = headline.replace(new RegExp('(Care)'), `<span class=${bem('highlight').className}>$1</span>`);

  return (
    <Content {...bem()} useOwnSpacing>
      <h1 {...bem('headline')}>{parse(output)}</h1>
      {description && <p {...bem('description')}>{description}</p>}
      <FluidPicture
        {...image}
        maxWidth={1920}
        widths={[480, 960, 1920]}
        role="presentation"
        {...bem('image')}
      />
    </Content>
  );
};

GenericHeading.propTypes = {
  headline: string.isRequired,
  description: string,
  image: arrayOf(pictureFluidType).isRequired,
};

GenericHeading.defaultProps = {
  description: undefined,
};

export const fragment = graphql`
  fragment GenericHeadingFragment on CraftGQL_pageHeadings_genericHeading_BlockType {
    headline
    description
    image {
      ...Picture
      fluid_0: url(width: 480, mode: "crop", position: "") # 0.25
      fluid_1: url(width: 960, mode: "crop", position: "") # 0.5
      fluid_2: url(width: 1920, mode: "crop", position: "") # max width
    }
  }
`;
